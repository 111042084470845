.Trials {
	padding: 0.5rem;
	> form {
		padding: 1rem;
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-grow: 2;
		justify-content: space-around;
		.SearchField {
			width: 100%;
		}
	}
	.ChoicesYesSelected {
		color: green;
	}
	.ChoicesNaSelected {
		color: orange;
	}
	.ChoicesNoSelected {
		color: red;
	}
	.TrialAction {
		margin: .5rem;
	}
}

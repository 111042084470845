.Promos {
	padding: 0.5rem;
	> form {
		padding: 1rem;
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-grow: 2;
		justify-content: space-around;
		.SearchField {
			width: 55%;
		}
		.SelectField {
			width: 10%;
		}
		.AddField {
			width: 10%;
			text-align: center;
			vertical-align: center;
		}
	}
	.PromoCorpRestrict {
		color: #ac1a23;
	}
	.PromoCorp {
		color: #0e830a;
	}
	.PromoCreate {

	}
}

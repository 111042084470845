.TextEditor {
	&.-disabled {
		opacity: .5;
		.ql-container {
			border-radius: .4rem;
		}
	}
	.ql-container {
		border-bottom-left-radius: .4rem;
		border-bottom-right-radius: .4rem;
	}
	.ql-snow.ql-toolbar {
		background: #eaecec;
		border-top-left-radius: .4rem;
		border-top-right-radius: .4rem;
	}
}

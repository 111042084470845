.TeacherLesson {
	> ol {
		list-style: none;
		text-align: center;
		width: 100%;
		> li {
			display: inline;
			> button {
				margin: .25rem;
				padding: .25rem;
				cursor: default;
				text-transform: none;
				> span > button {
					cursor: pointer;
					background: none;
					border: 1px solid #ee0505;
					color: #ee0505;
					border-radius: 15%;
				}
			}
		}
	}
}

.Users {
  padding: 0.5rem;
  > div:nth-child(1) {
	padding: 1rem;

	.SearchField {
	  width: 100%;
	}
  }
}

.Planning {
	padding: .5rem;
	width: 100%;
	> form {
		padding: 1rem;
		.ButtonField {
			display: flex;
			justify-content: center;
			padding-top: .8rem;
			> Button {
				text-transform: none;
			}
		}
	}
}
.SeanceList {
	.Seances {
		cursor: pointer;
		min-height: 2rem;
		min-width: 5rem;
		text-transform: none;
		> label {
			text-overflow: ellipsis;
		}
	}
}
.SeanceDetail {
	padding-top: 1rem !important;
}

.Corporates {
	padding: 0.5rem;
	> form {
		padding: 1rem;
	}
}
.SpanLink {
	cursor: pointer;
	color: blue;
	text-decoration: underline;
}

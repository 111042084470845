.VersionChecker {
	padding: 2rem;
	> div {
		padding: 2rem;
		display: flex;
		flex-direction: column;

		> span {
			align-self: center;
		}

		> h1 {
			align-self: center;
			font-size: 2rem;
		}

		> em {
			align-self: center;
			font-size: 1.5rem;
			text-decoration: underline red;
		}

		> button {
			align-self: center;
			margin: 2rem;
			width: 10rem;
		}

		> div {
			bottom: 0;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			> p {
				font-size: .75rem;
			}
		}
	}
}
